import { Box, Button, Flex, Icon, Image, Text, VStack } from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
import PageTitle from "../components/PageTitle";
import { useUserContext } from "./UserContext";

export const LogInPage = () => {
  const { logIn } = useUserContext();
  return (
    <>
      <PageTitle title="Sign In" />
      <Flex w="100vw" h="100vh" justifyContent="center" alignItems="center">
        <VStack>
          <Box width="4em">
            <Image src="/logo.png" alt="Dyno Dino" />
          </Box>
          <Text fontWeight="semibold" fontSize="3xl">
            Log in to your account
          </Text>
          <Text color="gray.600" fontSize="sm">
            Don't have an account? Let's get started!
          </Text>
          <Box>
            <Button
              boxShadow="medium"
              borderRadius={7}
              borderWidth={1.2}
              borderColor="black"
              mt="2"
              variant="solid"
              bg="white"
              leftIcon={<Icon as={FcGoogle} color="white" fontSize={24} />}
              onClick={() => logIn()}
            >
              <Text fontSize="16px" p="8px" color="gray.600">
                Sign in with Google
              </Text>
            </Button>
          </Box>
        </VStack>
      </Flex>
    </>
  );
};
